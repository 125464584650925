import { useQuery } from 'apollo-client'
import subscriptionCouponQuery, { type SubscriptionCouponVariables } from './graph/subscriptionCoupon.graphql'


export const useSubscriptionCoupon = (input: SubscriptionCouponVariables['input']) => {
  const { data, isFetching } = useQuery(subscriptionCouponQuery, {
    variables: {
      input,
    },
  })

  return {
    subscriptionCoupon: data?.currentUser?.data?.subscription?.coupon,
    isFetching,
  }
}
