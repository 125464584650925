export default {
  defaultName: {
    en: 'Wood Car Freshener',
  },
  defaultScent: {
    en: 'Scent of the Month',
  },
  name: {
    en: '{name} in {variety}',
  },
  nameWithClip: {
    en: '{name} in {variety} with {clip}',
  },
}
