import messages from './messages'


type GetDriftProductNameProps = {
  productName?: string
  varietyName: string
  driftDescription: string
  formatMessage: (message: Intl.Message) => string
}

const getFullDriftProductName = ({
  productName,
  varietyName,
  driftDescription,
  formatMessage,
}: GetDriftProductNameProps) => {

  if (!varietyName) {
    return productName
  }

  // TODO: Find a way to do this in a more flexible and fail-safe way? — added on 17–08–2023 by algeas
  const [ productNameFromDesc, clipNameFromDesc ] = driftDescription?.split(' with ') || []

  return formatMessage({
    ...(clipNameFromDesc ? messages.nameWithClip : messages.name),
    values: {
      clip: clipNameFromDesc,
      name: productNameFromDesc || productName || formatMessage(messages.defaultName),
      variety: varietyName || formatMessage(messages.defaultScent),
    },
  })
}


export default getFullDriftProductName
